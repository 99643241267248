<template>
  <div>
    <div class="dis align_center">
      <div class="icon"></div>
      <div class="service" v-if="correct">添加入企服务月度反馈表</div>
      <div class="service" v-else>添加入企服务月度反馈表</div>

    </div>

    <el-form :model="ruleForm"  :rules="rules" status-icon ref="ruleForm" label-width="266px" :label-position="labelPosition">
      <el-form-item label="入企编号" prop="mark" required>
        <el-input class="width_500" type="text" placeholder="请输入企编号" v-model="ruleForm.mark"
          autocomplete="off"></el-input>
      </el-form-item>
      <!-- <el-form-item label="姓名" prop="name" required>
        <el-input class="width_500" type="text" placeholder="请输入填报人姓名" v-model="ruleForm.name"
          autocomplete="off"></el-input>
      </el-form-item> -->
      <el-form-item label="服务企业数量" prop="enterprise_num" required>
        <el-input class="width_500" type="text" placeholder="请输入服务企业数量" v-model="ruleForm.enterprise_num"
          autocomplete="off"></el-input>
      </el-form-item>

      <el-form-item label="入企数量" prop="num" required>
        <el-input class="width_500" type="text" placeholder="请输入入企数量" v-model="ruleForm.num"
          autocomplete="off"></el-input>
      </el-form-item>

      <el-form-item label="对接需求数量" prop="dock_num" required>
        <el-input class="width_500" type="text" placeholder="请输入对接需求数量" v-model="ruleForm.dock_num"
          autocomplete="off"></el-input>
      </el-form-item>

      <el-form-item label="报送月份" prop="month" required>
        <el-date-picker v-model="ruleForm.month" type="month" placeholder="选择日期" value-format="yyyy-MM">
        </el-date-picker>
      </el-form-item>

      <el-form-item label="入企服务过程中遇到的困难" prop="difficulty" required>
        <el-input class="width_500" type="textarea" v-model="ruleForm.difficulty" :rows="5"
          placeholder="请输入入企服务过程中遇到的困难"></el-input>
      </el-form-item>

      <el-form-item label="服务典型案例" prop="cases" required>
        <el-input class="width_500" type="textarea" :rows="5" v-model="ruleForm.cases"
          placeholder="请输入服务典型案例"></el-input>
      </el-form-item>

      <el-form-item label="意见建议" prop="proposal" required>
        <el-input :rows="5" class="width_500" type="textarea" v-model="ruleForm.proposal"
          placeholder="请输入意见建议"></el-input>
      </el-form-item>

      <el-form-item label="入企服务具体情况" required>
        <el-button type="primary" style="background-color: #ff6618; border-color: #ff6618"
          @click="addfirm()">添加指标类型</el-button>
      </el-form-item>

      <div style="
          width: 1200px;
          background: #fafafa;
          border-radius: 5px;
          padding: 10px;
        " v-for="(itme, index) in ruleForm.target" :key="index">
       
        <div style="
            width: 60px;
            height: 20px;
            font-size: 18px;
            font-family: Source Han Sans SC Regular,
              Source Han Sans SC Regular-Regular;
            font-weight: 400;
            text-align: left;
            color: #333333;
            line-height: 20px;
            margin-bottom: 10px;
          ">
          企业{{ index + 1 }}
        </div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="企业名称" :prop="'target.' + index + '.name'" :rules="{
      required: true, message: '请输入企业名称', trigger: 'blur'
    }">
              <el-input type="text" v-model="ruleForm.target[index].name" placeholder="请输入企业名称" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="服务类别" :prop="'target.' + index + '.pro_cate_id'" :rules="{
      required: true, message: '请选择服务类别', trigger: 'blur'
    }" >
              <el-select v-model="ruleForm.target[index].pro_cate_id" placeholder="请选择服务类别">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="服务需求" :prop="'target.' + index + '.demand'" :rules="{
      required: true, message: '请输入服务需求', trigger: 'blur'
    }">
              <el-input type="text" placeholder="请输入服务需求" v-model="ruleForm.target[index].demand" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="对接形式" :prop="'target.' + index + '.dock'" :rules="{
      required: true, message: '请输入对接形式', trigger: 'blur'
    }"> 
              <el-input type="text" placeholder="请输入对接形式" v-model="ruleForm.target[index].dock" />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="是否需要联盟协调" :prop="'target.' + index + '.is_coordinate'"  :rules="{
      required: true, message: '请选择是否需要联盟协调', trigger: 'blur'
    }">
              <el-select v-model="ruleForm.target[index].is_coordinate  " placeholder="请选择是否需要联盟协调">
                <el-option v-for="item in alliance" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="服务典型案例介绍" :prop="'target.' + index + '.content'"  :rules="{
      required: true, message: '请输入服务典型案例介绍', trigger: 'blur'
    }">
              <el-input type="textarea" v-model="ruleForm.target[index].content" placeholder="请输入服务典型案例介绍"
                :rows="6"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>

      <el-button type="primary" @click="upfeedback()" v-if="correct"
        style="background-color: #ff6618; border-color: #ff6618">确定修改</el-button>
      <el-button type="primary" @click="addfeedback()" v-else
        style="background-color: #ff6618; border-color: #ff6618">确定添加</el-button>

      <el-button type="primary" @click="back()" style="background-color: #cfcfcf; border-color: #cfcfcf">取消</el-button>
    </el-form>
  </div>
</template>
<script >
export default {
  data() {
    return {
      correct: "",
      ruleForm: {
        // name: "",
        num: "",
        difficulty: "",
        cases: "",
        enterprise_num: "",
        proposal: "",
        dock_num: "",
        // mark: "",
        month: "",
        target: [
          {
            name: "", //名字
            pro_cate_id: "", //服务类别
            demand: "", //服务需求
            dock: "", //对接
            is_coordinate: "", //盟调
            content: "", //服务案例介绍
          },
        ],
      },
       rules: {

        mark: [
          { required: true, message: "请输入入企编号", trigger: "blur" },
          { required: true, message: "入企编号不能为空", trigger: "change" },
        ],
        enterprise_num: [
          { required: true, message: "请输入服务企业数量", trigger: "blur" },
          { required: true, message: "服务企业数量不能为空", trigger: "change" },
        ],
        num:  [
          { required: true, message: "请输入入企数量", trigger: "blur" },
          { required: true, message: "入企数量不能为空", trigger: "change" },
        ],
        dock_num:  [
          { required: true, message: "请输入对接需求数量", trigger: "blur" },
          { required: true, message: "对接需求数量不能为空", trigger: "change" },
        ],

        month: [{ required: true, message: "请选择报送月份",trigger: "blur" }],
        difficulty: [
          { required: true, message: "请输入入企服务过程中遇到的困难", trigger: "blur" },
          { required: true, message: "入企服务过程中遇到的困难不能为空", trigger: "change" },
        ],
        proposal: [
          { required: true, message: "请输入意见建议", trigger: "blur" },
          { required: true, message: "意见建议不能为空", trigger: "change" },
        ],
        cases: [
          { required: true, message: "请输入服务典型案例", trigger: "blur" },
          { required: true, message: "服务典型案例不能为空", trigger: "change" },
        ],
        // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
      },



      labelPosition: "left",
      options: [],
      alliance: [
        { label: "是", value: "1" },
        { label: "否", value: "2" },
      ],
    };
  },
  methods: {
    back() {
      this.$router.push("/feedback");
    },
    addfirm() {
      console.log("5464");
      this.ruleForm.target.push({
        name: "",
        pro_cate_id: "",
        demand: "", //服务需求
        dock: "", //对接
        is_coordinate: "", //盟调
        content: "", //服务案例介绍
      });
      console.log(this.ruleForm.target, 'this.ruleForm.target')
    },
    //下拉内容
    getcatelist() {
      let data = { token: localStorage.eleToken };
      this.$get("cate_list", data).then((res) => {
        this.options = res.result.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        console.log(this.options, "this.options");
      });
    },
    //添加反馈表
    addfeedback() {
      let data = {
        token: localStorage.eleToken,
        // name: this.ruleForm.name,
        mark: this.ruleForm.mark,
        num: this.ruleForm.num,
        dock_num: this.ruleForm.dock_num,
        month: this.ruleForm.month,
        difficulty: this.ruleForm.difficulty,
        cases: this.ruleForm.cases,
        proposal: this.ruleForm.proposal,
        enterprise_num: this.ruleForm.enterprise_num,
        report: this.ruleForm.target,
      };
      console.log(data, "data");
      this.$post("monthly_report_add", data).then((res) => {
        console.log(res, "res");
        this.$message({
          message: res.msg,
          type: "success",
          duration: 1500,
        });
        this.$router.push("/feedback");
      });
    },
    //添加反馈表
    upfeedback() {
      let data = {
        id: this.correct,
        token: localStorage.eleToken,
        // name: this.ruleForm.name,
        mark: this.ruleForm.mark,
        num: this.ruleForm.num,
        dock_num: this.ruleForm.dock_num,
        month: this.ruleForm.month,
        difficulty: this.ruleForm.difficulty,
        cases: this.ruleForm.cases,
        proposal: this.ruleForm.proposal,
        enterprise_num: this.ruleForm.enterprise_num,
        report: this.ruleForm.target,
      };
      console.log(data, "data");
      this.$post("monthly_report_upd", data).then((res) => {
        console.log(res, "res");
        this.$message({
          message: res.msg,
          type: "success",
          duration: 1500,
        });
        this.$router.push("/feedback");
      });
    },
    getInfo() {
      let data = {
        token: localStorage.eleToken,
        id: this.correct
      };
      this.$get("monthly_report_info", data).then((res) => {
        this.ruleForm = res.result
        this.ruleForm.target = res.result.report
        res.result.report.map((item,index)=>{
          if(item.is_coordinate == 1){
            this.ruleForm.target[index].is_coordinate = '是'
          }else{
            this.ruleForm.target[index].is_coordinate = '否'
          }
        })
        console.log(res, "res");
      });
    },

  },
  created() {
    this.getcatelist();
    console.log(this.$route.query, 'this.$route.query')
    if (this.$route.query.id) {
      this.correct = this.$route.query.id
      this.getInfo()
    }
  },
};
</script>
<style scoped>
.icon {
  width: 4px;
  height: 19px;
  background: #fb1010;
  border-radius: 2px;
}

.service {
  height: 30px;
  font-size: 24px;
  font-family: Source Han Sans SC Medium, Source Han Sans SC Medium-Medium;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 30px;
  margin-left: 10px;
}

.width_500 {
  width: 500px;
}
</style>